<template>
  <div v-if="employee" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="employee">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Persönliche Angaben</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('title')"
            label-for="title"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.title" type="text" :placeholder="$t('title')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('lastname')"
            label-for="lastname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.lastname" type="text" :placeholder="$t('lastname')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('birthName')"
            label-for="birthName"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.birthName" type="text" :placeholder="$t('birthName')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('firstname')"
            label-for="firstname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.firstname" type="text" :placeholder="$t('firstname')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('dateOfBirth')"
            label-for="dateOfBirth"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <Calendar
              v-model="dateOfBirth"
              selectionMode="single"
              dateFormat="dd.mm.yy"
              :placeholder="$t('date')"
              :showWeek="true"
              :yearNavigator="true"
              :monthNavigator="true"
              yearRange="1930:2015"
            >
            </Calendar>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('placeOfBirth')"
            label-for="placeOfBirth"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.placeOfBirth"
              type="text"
              :placeholder="$t('placeOfBirth')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('countryOfOrigin')"
            label-for="countryOfOrigin"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.countryOfOrigin"
              type="text"
              :placeholder="$t('countryOfOrigin')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('gender')"
            label-for="gender"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('gender') + ' ' + $t('select')"
              v-model="employee.gender"
              :options="getEnumValues('Gender')"
              :show-labels="true"
              :allow-empty="false"
              :taggable="true"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <b-badge>{{ $t(option) }}</b-badge>
              </template>
              <template slot="option" slot-scope="{ option }">
                <b-badge>{{ $t(option) }}</b-badge>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('maritialStatus')"
            label-for="maritialStatus"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('maritialStatus') + ' ' + $t('select')"
              v-model="employee.maritialStatus"
              :options="getEnumValues('MaritialStatus')"
              :show-labels="true"
              :allow-empty="false"
              :taggable="true"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge>{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge>
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('nationality')"
            label-for="nationality"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.nationality"
              type="text"
              :placeholder="$t('nationality')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('motherTongue')"
            label-for="motherTongue"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.motherTongue"
              type="text"
              :placeholder="$t('motherTongue')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('languagesOfCommunication')"
            label-for="languagesOfCommunication"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.languagesOfCommunication"
              type="text"
              :placeholder="$t('exampleLanguages')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('costCenter')"
            label-for="costCenter"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.costCenter"
              type="text"
              :placeholder="$t('costCenter')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('internalNumber')"
            label-for="internalNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.internalNumber"
              type="text"
              :placeholder="$t('internalNumber')"
            />
          </b-form-group>
          <legend>
            <strong> Bankverbindung</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('bankAccountNumber')"
            label-for="bankAccountNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.bankAccountNumber"
              type="text"
              :placeholder="$t('bankAccountNumber')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('sortCode')"
            label-for="sortCode"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.sortCode" type="text" :placeholder="$t('sortCode')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('bic')"
            label-for="bic"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.bic" type="text" :placeholder="$t('bic')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('iban')"
            label-for="iban"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.iban" type="text" :placeholder="$t('iban')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('nameOfBank')"
            label-for="nameOfBank"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.nameOfBank"
              type="text"
              :placeholder="$t('nameOfBank')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('altAccHolder')"
            label-for="altAccHolder"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.altAccHolder"
              type="text"
              :placeholder="$t('altAccHolder')"
            />
          </b-form-group>
          <legend>
            <strong>Lohnsteuerdaten</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('taxNumber')"
            label-for="taxNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.taxNumber" type="text" :placeholder="$t('taxNumber')" />
          </b-form-group>
          <legend>
            <strong>Rentenversicherung</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('socialSecurityNr')"
            label-for="socialSecurityNr"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.socialSecurityNr"
              type="text"
              :placeholder="$t('socialSecurityNr')"
            />
          </b-form-group>
          <legend>
            <strong>Krankenversicherung gesetzlich</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('healthInsurance')"
            label-for="healthInsurance"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.healthInsurance"
              type="text"
              :placeholder="$t('healthInsurance')"
            />
          </b-form-group>
          <legend>
            <strong>Krankenversicherung privat</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('privateHealthInsurance')"
            label-for="privateHealthInsurance"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.privateHealthInsurance"
              type="text"
              :placeholder="$t('privateHealthInsurance')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('lastHealthInsurance')"
            label-for="lastHealthInsurance"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.lastHealthInsurance"
              type="text"
              :placeholder="$t('lastHealthInsurance')"
            />
          </b-form-group>
          <legend>
            <strong>Urlaubsabbau bei dem vorherigen Arbeitgeber</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('vacation')"
            label-for="vacation"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <SelectButton
              id="vacation"
              v-model="vacation"
              :options="vacationOptions"
              optionLabel="label"
              optionValue="value"
              :style="{ display: 'flex', gap: '10px' }"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('vacationDays')"
            label-for="vacationDays"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.vacationDays"
              type="text"
              :placeholder="$t('vacationDays')"
            />
          </b-form-group>
          <legend>
            <strong>Ausbildungsdaten</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('highestDegreeSchool')"
            label-for="highestDegreeSchool"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.highestDegreeSchool"
              type="text"
              :placeholder="$t('highestDegreeSchool')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('highestSchoolDegree')"
            label-for="highestSchoolDegree"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.highestSchoolDegree"
              type="text"
              :placeholder="$t('highestSchoolDegree')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('achievementSchoolDegree')"
            label-for="achievementSchoolDegree"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <Calendar
              v-model="achievementSchoolDegree"
              selectionMode="single"
              dateFormat="dd.mm.yy"
              :placeholder="$t('date')"
              :showWeek="true"
              :yearNavigator="true"
              :monthNavigator="true"
              yearRange="1930:2021"
            >
            </Calendar>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('institution')"
            label-for="institution"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.institution"
              type="text"
              :placeholder="$t('institution')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('academicStartAt')"
            label-for="academicStartAt"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <Calendar
              v-model="academicStartAt"
              dateFormat="dd.mm.yy"
              :placeholder="$t('date')"
              :showWeek="true"
              :yearNavigator="true"
              :monthNavigator="true"
              yearRange="1930:2021"
            >
            </Calendar>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('academicEnAt')"
            label-for="academicEnAt"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <Calendar
              v-model="academicEnAt"
              dateFormat="dd.mm.yy"
              :placeholder="$t('date')"
              :showWeek="true"
              :yearNavigator="true"
              :monthNavigator="true"
              yearRange="1930:2021"
            >
            </Calendar>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('achievement')"
            label-for="achievement"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <Calendar
              v-model="achievement"
              dateFormat="dd.mm.yy"
              :placeholder="$t('date')"
              :showWeek="true"
              :yearNavigator="true"
              :monthNavigator="true"
              yearRange="1930:2021"
            >
            </Calendar> </b-form-group
        ></b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('state') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('state')"
            label-for="activeState"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('state') + ' ' + $t('select')"
              v-model="employee.activeState"
              :options="getEnumValues('ActiveState')"
              :show-labels="true"
              :allow-empty="false"
              :taggable="true"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="getActiveStateColor(option)">{{
                  $t(option)
                }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="getActiveStateColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </b-form-group>

          <legend>
            <strong>{{ $t('users') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('users')"
            label-for="activeState"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              class="multiselect-users"
              v-model="employee.users"
              :options="filteredUsers"
              track-by="number"
              v-on:search-change="onFilterUsers"
              v-on:select="onSelectUser"
              v-on:remove="onSelectUser"
              :filter="true"
              :internal-search="false"
              :close-on-select="false"
              :clear-on-select="false"
              :multiple="true"
              :show-labels="false"
              :allow-empty="true"
              :deselectLabel="$t('deselectLabel')"
              selectLabel=""
              selectedLabel=""
              :placeholder="'Benutzer ' + $t('select') + '/suchen'"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="tag" slot-scope="{ option }">
                <div>{{ option.firstname }} {{ option.lastname }} - {{ option.email }}</div>
              </template>
              <template slot="noResult">
                <div>{{ $t('no_results') }}</div>
              </template>
            </multiselect>
          </b-form-group>
          <legend>
            <strong>Kontakt</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('mobile')"
            label-for="mobilePhone"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.mobilePhone" type="text" :placeholder="$t('mobile')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('email')"
            label-for="email"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.email" type="text" :placeholder="$t('email')" />
          </b-form-group>
          <legend>
            <strong>Aktuelle Anschrift (Hauptwohnsitz)</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('streetAndNumber')"
            label-for="street"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.street"
              type="text"
              :placeholder="$t('streetAndNumber')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('zip')"
            label-for="zip"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.zip" type="text" :placeholder="$t('zip')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('addition')"
            label-for="addition"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.addition" type="text" :placeholder="$t('addition')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('district')"
            label-for="district"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.district" type="text" :placeholder="$t('district')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('city')"
            label-for="city"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.city" type="text" :placeholder="$t('city')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('phone')"
            label-for="phone"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.phone" type="text" :placeholder="$t('phone')" />
          </b-form-group>
          <legend>
            <strong>Zweitanschrift / Nebenwohnsitz (z.B. doppelte Haushaltsführung)</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('streetAndNumber')"
            label-for="street2"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.street2"
              type="text"
              :placeholder="$t('streetAndNumber')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('zip')"
            label-for="zip"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.zip2" type="text" :placeholder="$t('zip')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('addition')"
            label-for="addition"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.addition2" type="text" :placeholder="$t('addition')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('district')"
            label-for="district"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.district2" type="text" :placeholder="$t('district')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('city')"
            label-for="city"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.city2" type="text" :placeholder="$t('city')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('phone')"
            label-for="phone"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.phone2" type="text" :placeholder="$t('phone')" />
          </b-form-group>
          <legend>
            <strong>Notfall / Bezugsperson</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('emergencyContact')"
            label-for="emergencyContact"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.emergencyContact"
              type="text"
              :placeholder="$t('emergencyContact')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('emergencyPhone')"
            label-for="emergencyPhone"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.emergencyPhone"
              type="text"
              :placeholder="$t('emergencyPhone')"
            />
          </b-form-group>
          <legend>
            <strong>Kinder</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('numberOfKids')"
            label-for="childCount"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <SelectButton
              id="childCount"
              v-model="childCount"
              :options="childCountOptions"
              optionLabel="label"
              optionValue="value"
            />
          </b-form-group>

          <div v-for="(child, index) in employee.children" :key="index">
            <legend>
              <strong>Kind {{ index + 1 }}</strong>
            </legend>

            <b-form-group
              horizontal
              :label="$t('firstname')"
              label-for="firstname"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="child.firstName" type="text" :placeholder="$t('firstname')" />
            </b-form-group>

            <b-form-group
              horizontal
              :label="$t('lastname')"
              label-for="lastname"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="child.lastName" type="text" :placeholder="$t('lastname')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('dateOfBirth')"
              :label-for="'childBirthDate' + index"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <Calendar
                :value="child.birthDate ? new Date(child.birthDate) : null"
                @input="updateChildBirthDate($event, index)"
                dateFormat="dd.mm.yy"
                :placeholder="$t('date')"
                :showWeek="true"
                :yearNavigator="true"
                :monthNavigator="true"
                yearRange="1930:2021"
              />
            </b-form-group>
          </div>
          <legend>
            <strong>Behinderung</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('severeDisability')"
            label-for="severeDisability"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.severeDisability"
              type="text"
              :placeholder="$t('severeDisability')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('disabilityDegree')"
            label-for="disabilityDegree"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="employee.disabilityDegree"
              type="text"
              :placeholder="$t('disabilityDegree')"
            />
          </b-form-group>
          <legend>
            <strong>Upload</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('projectAttachmentType')"
            label-for="projectAttachmentType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('documentType')"
              v-model="selectedEmployeeAttachmentType"
              id="employeeAttachmentType"
              :options="getEmployeeAttachmentTypes"
              :allow-empty="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
            </multiselect>

            <DropzoneUpload
              class="mt-2"
              :complete="isAttachmentUploadComplete"
              :parentId="employee._id"
              :parentType="selectedEmployeeAttachmentType"
              :uploadCallback="uploadEmployeeAttachment"
              :disabled="isUploadDisabled"
              v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
            />
          </b-form-group>
          <legend>
            <strong>{{ $t('childrenAttachments') }}</strong>
          </legend>
          <AttachmentList
            :attachments="employee.childrenAttachments"
            attachmentType="childrenAttachments"
            v-on:attachment-list:delete="onDeleteEmployeeAttachment"
          ></AttachmentList>
          <legend>
            <strong>{{ $t('disabilityAttachments') }}</strong>
          </legend>
          <AttachmentList
            :attachments="employee.disabilityAttachments"
            attachmentType="disabilityAttachments"
            v-on:attachment-list:delete="onDeleteEmployeeAttachment"
          ></AttachmentList>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="employee"
            modelEditRoute="EmployeeEditPage"
            modelListRoute="EmployeesPage"
            modelRouteParamName="employeeNumber"
            :updateCallback="updateEmployee"
            :createCallback="createEmployee"
            :deleteCallback="deleteEmployee"
            :fetchPaginatedCallback="fetchEmployees"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import AttachmentList from '@/components/AttachmentList';
import ButtonBar from '@/components/ButtonBar.vue';
import DropzoneUpload from '@/components/DropzoneUpload';
import { getActiveStateColor } from '@/helpers/colors';
import Calendar from 'primevue/calendar';
import SelectButton from 'primevue/selectbutton';
import employees from '../../store/employees';

export default {
  name: 'EmployeeEditPage',
  components: {
    Widget,
    Breadcrumbs,
    Multiselect,
    DropzoneUpload,
    AttachmentList,
    ButtonBar,
    Calendar,
    SelectButton,
  },
  props: {
    employeeNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isAttachmentUploadComplete: false,
      selectedEmployeeAttachmentType: null,

      childCountOptions: [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
      ],
      vacationOptions: [
        { label: 'Ja', value: true },
        {
          label: 'Nein, nicht zutreffend, da eWay GmbH meinerster Job in diesem Jahr ist',
          value: false,
        },
      ],
      number: null,
      filteredUsers: [],
    };
  },
  computed: {
    ...mapGetters([
      'getEmployee',
      'getEmployeeByNumber',
      'getEnumValues',
      'getUsers',
      'getEmployeeAttachmentTypes',
    ]),
    isUploadDisabled() {
      return this.selectedEmployeeAttachmentType === null ? true : false;
    },
    buttons() {
      return {
        showDeleteButton: this.employee && this.employee.number !== '_new' ? true : false,
      };
    },
    dateOfBirth: {
      get() {
        return this.$calendarFormat(this.employee.dateOfBirth);
      },
      set(value) {
        this.employee.dateOfBirth = value;
      },
    },
    vacation: {
      get() {
        return this.employee.vacation;
      },
      set(value) {
        this.$set(this.employee, 'vacation', value);
      },
    },
    childCount: {
      get() {
        if (!this.employee || !this.employee.children) return 0;
        return this.employee.children.length;
      },
      set(newCount) {
        if (!this.employee) return;

        if (!this.employee.children) {
          this.$set(this.employee, 'children', []);
        }

        const oldCount = this.employee.children.length;
        if (newCount > oldCount) {
          for (let i = oldCount; i < newCount; i++) {
            this.employee.children.push({
              firstName: '',
              lastName: '',
              birthDate: null,
            });
          }
        } else if (newCount < oldCount) {
          this.employee.children.splice(newCount);
        }
      },
    },
    academicEnAt: {
      get() {
        return this.$calendarFormat(this.employee.academicEnAt);
      },
      set(value) {
        this.employee.academicEnAt = value;
      },
    },
    achievement: {
      get() {
        return this.$calendarFormat(this.employee.achievement);
      },
      set(value) {
        this.employee.achievement = value;
      },
    },
    academicStartAt: {
      get() {
        return this.$calendarFormat(this.employee.academicStartAt);
      },
      set(value) {
        this.employee.academicStartAt = value;
      },
    },

    achievementSchoolDegree: {
      get() {
        return this.$calendarFormat(this.employee.achievementSchoolDegree);
      },
      set(value) {
        this.employee.achievementSchoolDegree = value;
      },
    },

    employee() {
      return this.getEmployeeByNumber(this.number);
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('employee'), route: { name: 'EmployeesPage' } },
        { name: this.employee.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'fetchEmployees',
      'fetchEmployeeByNumber',
      'updateEmployee',
      'createEmployee',
      'deleteEmployee',
      'fetchEnumValues',
      'initEmployee',
      'fetchUsers',
      'uploadEmployeeAttachment',
      'deleteEmployeeAttachment',
      'fetchEmployeeAttachmentTypes',
    ]),
    async onUploadEmployeeAttachment(payload) {
      await this.uploadEmployeeAttachment(payload);
    },
    onDeleteEmployeeAttachment(event) {
      this.deleteEmployeeAttachment({
        employeeId: this.employee._id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
    /**
     *
     */
    updateChildBirthDate(newDate, index) {
      if (newDate instanceof Date && !isNaN(newDate.getTime())) {
        this.employee.children[index].birthDate = newDate;
      } else {
        this.employee.children[index].birthDate = null;
      }
    },
    async onFilterUsers(query) {
      console.log(query);
      this.filteredUsers = [];
      this.getUsers.map((user) => {
        if (
          (user.firstname && user.firstname.toLowerCase().indexOf(query) >= 0) ||
          (user.lastname && user.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          console.log(user);
          this.filteredUsers.push(user);
        }
      });
    },

    /**
     *
     */
    async onSelectUser(user) {
      console.log(user);
      // const { data } = user;
      // this.save(data);
    },

    /**
     *
     */
    // async save(employee) {
    //   if (employee.number === '_new') {
    //     await this.createEmployee(employee);
    //   } else {
    //     await this.updateEmployee(employee);
    //   }
    // },

    getActiveStateColor,
  },
  async mounted() {
    if (this.employeeNumber) {
      this.number = this.employeeNumber;
      await this.fetchEmployeeByNumber(this.number);
    } else {
      await this.initEmployee();
      this.number = '_new';
      this.$set(this.employee, 'children', []);
    }
    await this.fetchEnumValues('ActiveState');
    await this.fetchEnumValues('Gender');
    await this.fetchEnumValues('MaritialStatus');
    await this.fetchUsers();
    await this.fetchEmployeeAttachmentTypes();
    this.filteredUsers = this.getUsers;
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadEmployeeAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.custom-datepicker {
  width: 100%;
}

.custom-datepicker .p-inputtext {
  height: 38px;
  padding: 6px 12px;
  font-size: 1rem;
}
</style>
